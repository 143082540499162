import ProGalleryComponent from '../components/ProGallery/viewer/ProGallery';


const ProGallery = {
  component: ProGalleryComponent
};


export const components = {
  ['ProGallery']: ProGallery
};

