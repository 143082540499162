import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  WixProGallery as BaseGallery,
  makeEventListener,
  isFullscreenEnabled,
} from '@wix/pro-gallery-core-wrapper';

import {
  formatClassNames,
  debounce,
  getDataAttributes,
  getQaDataAttributes,
} from '@wix/editor-elements-common-utils';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import type {
  IProGalleryProps,
  GalleryViewMode,
  IProGalleryImperativeActions,
  ProGalleryItems,
} from '../ProGallery.types';
import { isEditingHover, formatItemUri } from '../common/utils';
import getExperimentalFeatures from '../common/getExperimentalFeatures';
import semanticClassNames from '../WIP_ProGallery.semanticClassNames';

import { st, classes } from './style/ProGallery.component.st.css';
import './style/ProGallery.global.scss';
import { useOptions } from './useOptions';
import { useScrollBase } from './useScrollBase';

const RESIZE_OBSERVER_DELAY = 100;
const defaultContainers = {
  desktop: {
    width: 980,
    height: 500,
  },
  mobile: {
    width: 320,
    height: 500,
  },
};
const useIsPrerender = () => {
  const [isPrerender, setIsPrerender] = useState(true);
  useEffect(() => {
    setIsPrerender(false);
  }, []);
  return isPrerender;
};

const useHeight = (
  props: Pick<
    IProGalleryProps,
    'options' | 'editorLayoutHeight' | 'editorLayoutWidth' | 'deviceType'
  >,
  setIsMeasuredContainer: (isMeasuredContainer: boolean) => void,
) => {
  const { editorLayoutHeight, editorLayoutWidth, options, deviceType } = props;
  const isPrerender = useIsPrerender();
  const { responsive } = options;
  const { mobile, desktop } = defaultContainers;
  const isMobile = deviceType === 'mobile';

  const containerWidthResponsive = isMobile ? mobile.width : desktop.width;
  const containerHeightResponsive = isMobile ? mobile.height : desktop.height;

  const defaultContainer = {
    isDefaultContainer: true,
    width: responsive ? containerWidthResponsive : editorLayoutWidth,
    height: responsive ? containerHeightResponsive : editorLayoutHeight,
  };
  const [container, setContainer] = useState<any>(defaultContainer);
  const [height, setHeight] = useState<number>(container.height);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(
    () => setHeight(editorLayoutHeight || height),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorLayoutHeight],
  );
  const resizeGallery = useCallback(() => {
    setContainer({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
    });
    setIsMeasuredContainer(true);
  }, [containerRef, setIsMeasuredContainer]);

  const resizeObserverCallback = useMemo(
    () => debounce(resizeGallery, RESIZE_OBSERVER_DELAY),
    [resizeGallery],
  );
  useResizeObserver({
    ref: containerRef,
    callback: resizeObserverCallback,
  });
  const heightStyle = responsive ? {} : { height };
  const setHeightImp = (newHeight: number): void => {
    setHeight(newHeight);
    if (responsive) {
      setContainer({
        ...container,
        height: newHeight,
      });
    }
  };

  const containerClassName = isPrerender ? classes['container-prerender'] : '';

  return {
    setHeight: setHeightImp,
    container,
    containerRef,
    containerStyle: heightStyle,
    containerClassName,
  };
};
const useOptionsUpdateObserver = (forceUpdate: () => void) => {
  const variantResizeWatcherRef = useRef<HTMLDivElement>(null);
  const variantResizeWatcher = useCallback(() => {
    forceUpdate();
  }, [forceUpdate]);

  useResizeObserver({
    ref: variantResizeWatcherRef,
    callback: variantResizeWatcher,
  });

  return { variantResizeWatcherRef };
};
const ProGallery: React.ForwardRefRenderFunction<
  IProGalleryImperativeActions,
  IProGalleryProps
> = (props, ref) => {
  const {
    id,
    items: unprocessedItems,
    wixSDKItems,
    itemsSrc = 'organizeMedia',
    className,
    customClassNames = [],
    stylableClassName,
    forceState = {},
    onItemClicked = () => {},
    onCurrentItemChanged = () => {},
    onGetMoreItems = () => {},
    onGalleryNavigationStart = () => {},
    isExternalPagination,
    totalItemsCount,
    onMouseEnter,
    onMouseLeave,
    imageOnClickAction,
    isQaMode,
    fullNameCompType,
    shouldUseNewInfoElements,
    viewMode,
    deviceType,
    styleId,
    manualStyleParams,
    isInFirstFold,
    positionAbsoluteForFiniteHeightGalleries,
    // isInSeo - should be used to define prerender mode
  } = props;
  const [isMeasuredContainer, setIsMeasuredContainer] = useState<any>(false);
  const [isKnownOptions, setIsKnownOptions] = useState<any>(false);
  const {
    setHeight,
    container,
    containerRef,
    containerStyle,
    containerClassName,
  } = useHeight(props, setIsMeasuredContainer);
  const scrollBase = useScrollBase(containerRef);
  const isExperimentOpen = (name: string) => {
    const experiment = props.experiments?.[name];
    return !!experiment && experiment !== 'false';
  };
  const experimentalFeatures = getExperimentalFeatures(isExperimentOpen);
  if (shouldUseNewInfoElements) {
    experimentalFeatures.newInfoElements = shouldUseNewInfoElements;
  }
  const {
    options: convertedOptions,
    rawOptions,
    fullscreenOverrides,
    elementRef: rootRef,
    forceUpdate,
  } = useOptions({
    compProperties: props.options,
    isExperimentOpen,
    styleId,
    setIsKnownOptions,
    viewMode: viewMode || 'SITE',
    experimentalFeatures,
    manualStyleParams,
    imageOnClickAction,
    ...(viewMode === 'EDIT' && {
      editorOverrides: { alwaysShowHover: isEditingHover(forceState) },
    }),
  });

  const { variantResizeWatcherRef } = useOptionsUpdateObserver(forceUpdate);
  const [addedItems, setAddedItems] = useState<ProGalleryItems>([]);
  React.useImperativeHandle(ref, () => {
    return {
      addItems: (items: ProGalleryItems) => {
        setAddedItems(items);
      },
      next: () => {
        navigationApi?.next();
      },
      previous: () => {
        navigationApi?.previous();
      },
      navigateNextEnabled: () => {
        return navigationApi?.navigateNextEnabled();
      },
      navigatePreviousEnabled: () => {
        return navigationApi?.navigatePreviousEnabled();
      },
      currentIndex: () => {
        return navigationApi?.currentIndex();
      },
      triggerItemClick: (itemIndex: number) => {
        return navigationApi?.triggerItemClick(undefined, { itemIndex });
      },
      navigateToIndex: (itemIdx: Number, animationDuration: Number) => {
        return navigationApi?.navigateToIndex(itemIdx, animationDuration);
      },
    };
  });

  const items = useMemo(() => {
    return (
      (itemsSrc === 'wixSDK'
        ? wixSDKItems
        : unprocessedItems.map(formatItemUri)) || []
    );
  }, [unprocessedItems, wixSDKItems, itemsSrc]);
  if (shouldUseNewInfoElements) {
    experimentalFeatures.newInfoElements = shouldUseNewInfoElements;
  }

  // create a state to hold the navigation api
  const [navigationApi, setNavigationApi] = useState<any>(undefined);

  const eventsListener = makeEventListener()
    .ITEM_CLICKED((eventData: { idx: number; type: string }) => {
      onItemClicked({
        itemIndex: eventData.idx,
        type: eventData.type,
      });
    })
    .CURRENT_ITEM_CHANGED((eventData: { idx: number; type: string }) => {
      onCurrentItemChanged({
        itemIndex: eventData.idx,
        type: eventData.type,
      });
    })
    .NEED_MORE_ITEMS((eventData: number) => {
      onGetMoreItems({ from: eventData });
    })
    .GALLERY_NAVIGATION_START((eventData: any) => {
      onGalleryNavigationStart({
        animationDuration: eventData.scrollParams.durationInMS,
        continuousScroll: eventData.scrollParams.isContinuousScrolling,
        autoplay: !eventData.scrollParams.isManual,
        currentIndex: eventData.scrollParams.itemIdx,
        scrollDirection: eventData.scrollParams.scrollDirection,
        slideTransition: eventData.scrollParams.slideTransition,
      });
    })
    .NAVIGATION_API_READY((eventData: any) => {
      setNavigationApi(eventData);
    })
    .done();
  const isPrerenderMode =
    (!isKnownOptions || !isMeasuredContainer) && viewMode === 'SITE';
  return (
    <div
      id={id}
      className={st(classes.container, className, containerClassName)}
      ref={containerRef}
      style={containerStyle}
      {...getQaDataAttributes(isQaMode, fullNameCompType)}
      {...getDataAttributes(props)}
    >
      <div
        className={st(
          classes.root,
          stylableClassName,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={rootRef}
      >
        <div
          id="variant-resize-watcher"
          ref={variantResizeWatcherRef}
          style={
            {
              // we use styleId here to get the component variable (styleId is the same for all presets in this case)
              '--variant-count': `var(--${styleId}-ProGallery-variant_count)`,
            } as React.CSSProperties
          }
        />
        <BaseGallery
          isPrerenderMode={isPrerenderMode}
          experimentalFeatures={experimentalFeatures}
          id={id}
          items={items}
          itemsSrc={itemsSrc}
          container={{ ...container, scrollBase }}
          eventsListener={eventsListener}
          scrollingElement={containerRef.current as any}
          deviceType={deviceType!}
          isMobile={deviceType === 'mobile'}
          staticMediaUrls={undefined as any}
          viewMode={viewMode as GalleryViewMode}
          setLayoutHeightImp={setHeight}
          flattenOptions={convertedOptions}
          flattenFullscreenOverrides={fullscreenOverrides}
          enableFullscreen={isFullscreenEnabled(rawOptions)}
          isInFirstFold={isInFirstFold}
          isExternalPagination={isExternalPagination}
          addedItems={addedItems || []}
          totalItemsCount={totalItemsCount}
          positionAbsoluteForFiniteHeightGalleries={
            positionAbsoluteForFiniteHeightGalleries
          }
        />
      </div>
    </div>
  );
};

export default React.forwardRef(ProGallery);
